const form = document.getElementById('create-account-form');

if (form) {
    document.getElementById('create-account-form').addEventListener('submit', function(e) {
        const password = document.querySelector('#password').querySelector('input').value;
        const confirm_password = document.querySelector('#confirm_password').querySelector('input').value;
        const error = document.querySelector('[data-behaviour="title"]');

        if (!password) {
            e.preventDefault();
            error.innerHTML = 'Enter a password';
            error.classList.add('error');
        } else if (password !== confirm_password) {
            e.preventDefault();
            error.innerHTML = 'Passwords do not match';
            error.classList.add('error');
        }
    });
}